.rating-input .filled {
    color: coral;
  }
  
  .rating-input .empty {
    color: rgb(132, 132, 131);
  
  }
  .rating-input{
    display: flex;
  }
 




 